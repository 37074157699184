<template>
    <div>
        <!-- Loading state -->
        <div v-if="loading" class="loading">
            Loading...
        </div>

        <!-- Error state -->
        <div v-if="error" class="error">
            Error: {{ error.message }}
        </div>

        <!-- Data display -->
        <div v-if="!loading && !error">
            <!-- Render data here -->
            <ul>
                Redirecting ...
            </ul>
        </div>
    </div>
</template>
<script>
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import {
    SCAN_QUERY
} from "@/graphql";
export default {
    setup() {
        const route = useRoute();
        // Query definition
        const { result, loading, error, refetch, fetchMore } = useQuery(
            SCAN_QUERY,
            {
                dailyScanId: parseInt(route.params.id),
            }
        );

        // Computed properties for easy access to data
        const dailyScansServerSideAll = computed(
            () => result.value?.dailyScan
        );
        const scan_doc_file = computed(
            () => result.value?.dailyScan?.scan_doc_file
        );
        const scan_doc_name = computed(
            () => result.value?.dailyScan?.scan_doc_name
        );

        return {
            result,
            loading,
            error,
            refetch,
            fetchMore,
            dailyScansServerSideAll,
            scan_doc_file,
            scan_doc_name,
        };
    },
    async created() {
        // Wait until loading is false and result is available
        while (this.loading) {
            await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms
        }

        // Once loading is false, set localStorage items
        if (!this.error && this.dailyScansServerSideAll) {
            const dailyScan = this.dailyScansServerSideAll;
            localStorage.setItem("scan_id", this.$route.params.id);
            localStorage.setItem("scan_doc_file", dailyScan.scan_doc_file);
            localStorage.setItem("scan_doc_name", dailyScan.scan_doc_name);
            window.location.href = "/unique-id-validation"
        }
    },

};
</script>

<style scoped>
.loading,
.error {
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.loading {
    background-color: #f0f0f0;
}

.error {
    background-color: #ffe0e0;
    color: #ff0000;
}
</style>