<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <SideBar />
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <Topbar />
        <!-- Begin Page Content -->
        <div class="container-fluid">
          <!-- Page Heading -->
          <div class="d-sm-flex align-items-center justify-content-between mb-2">
            <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
          </div>
          <div class="text-muted mb-3">Scans overview &amp; summary</div>


          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">ONLINE INVOICES INSIGHTS</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Total Uninvoiced Online transactions Count
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.onlineTransactionsInsights.count }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Transactions Count Within SLA
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.onlineTransactionsInsights.withinSLA }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Transactions Count Outside SLA
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.onlineTransactionsInsights.outsideSLA }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                Total Uninvoiced Amount
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                $ {{ this.onlineTransactionsInsights.unInvoicedAmount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-money-bill fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 class="small font-weight-bold">Transactions Within SLA Percentage</h4>
                  <div class="progress mb-4">
                    <div class="progress-bar bg-success" role="progressbar"
                      :style="{ width: this.onlineTransactionsInsights.withinSlaPercentage + '%' }"
                      :aria-valuenow="this.onlineTransactionsInsights.withinSlaPercentage" aria-valuemin="0"
                      aria-valuemax="100">
                      Within SLA: {{ this.onlineTransactionsInsights.withinSlaPercentage }}%
                    </div>
                    <div class="progress-bar bg-danger" :role="progressbar"
                      :style="{ width: this.onlineTransactionsInsights.outsideSlaPercentage + '%' }"
                      :aria-valuenow="this.onlineTransactionsInsights.outsideSlaPercentage" aria-valuemin="0"
                      aria-valuemax="100">
                      Outside SLA: {{ this.onlineTransactionsInsights.outsideSlaPercentage }}%
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-md-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">SCANNED INVOICES INSIGHTS</h6>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Total Uninvoiced Scanned transactions Count
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.scannedInvoiceTransactionsInsights.count }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-file fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Transactions Count Outside SLA
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.scannedInvoiceTransactionsInsights.outsideSLA }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Transactions Count Within SLA
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.scannedInvoiceTransactionsInsights.withinSLA }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                Transactions Count Outside SLA
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                {{ this.onlineTransactionsInsights.outsideSLA }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-sort-numeric-up fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-xl-3 col-md-6 mb-4">
                      <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                Total Uninvoiced Amount
                              </div>
                              <div class="h5 mb-0 font-weight-bold text-gray-800">
                                $ {{ this.scannedInvoiceTransactionsInsights.unInvoicedAmount }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-money-bill fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 class="small font-weight-bold">Transactions Within SLA Percentage</h4>
                  <div class="progress mb-4">
                    <div class="progress-bar bg-success" role="progressbar"
                      :style="{ width: this.scannedInvoiceTransactionsInsights.withinSlaPercentage + '%' }"
                      :aria-valuenow="this.scannedInvoiceTransactionsInsights.withinSlaPercentage" aria-valuemin="0"
                      aria-valuemax="100">
                      Within SLA: {{ this.scannedInvoiceTransactionsInsights.withinSlaPercentage }}%
                    </div>
                    <div class="progress-bar bg-danger" :role="progressbar"
                      :style="{ width: this.scannedInvoiceTransactionsInsights.outsideSlaPercentage + '%' }"
                      :aria-valuenow="this.scannedInvoiceTransactionsInsights.outsideSlaPercentage" aria-valuemin="0"
                      aria-valuemax="100">
                      Outside SLA: {{ this.scannedInvoiceTransactionsInsights.outsideSlaPercentage }}%
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

          <!-- Content Row -->
          <div class="row">
            <div class="col-xl-12 col-lg-12 mb-2">
              <div class="card shadow">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Turnover Assigned Scans
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row" v-if="this.turnOverAssignedScans.length > 0">
                    <!-- Earnings (Monthly) Card Example -->
                    <div v-for="srt in turnOverAssignedScans" v-bind:key="srt.assigned_id"
                      class="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-2 hazard-blink mt-2">
                      <div class="card border-left-danger shadow h-100 py-2"
                        @click="redirectToScan(srt.scan_id.scan_id)">
                        <div class="card-body">
                          <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                              <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                {{ srt.scan_id.scan_doc_name }}
                              </div>
                              <div class="text-s mb-0 font-weight-bold text-danger">
                                Invoice No: {{ srt.scan_id.no_invoices }}
                              </div>
                              <div class="text-s mb-0 font-weight-bold text-warning">
                                Agent:
                                {{
                                  srt.scan_id.processor !== null
                                    ? srt.scan_id.processor.first_name
                                    : null
                                }}
                              </div>
                            </div>
                            <div class="col-auto">
                              <img class="cursor-pointer" href=""
                                src="https://img.icons8.com/?size=1x&id=kuOHmVeRoVF5&format=png"
                                style="width: 50px; height: 50px" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Repeat the above div for each card -->
                  </div>

                  <div class="pt-4" v-else>
                    <div class="card shadow h-100 py-2">
                      <div class="card-body">
                        <h6 class="text-center mb-0">No Assigned Scans</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 mb-4">
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    {{ year }} Summary - Transaction Count and Amount Per agency per Month
                  </h6>
                </div>
                <div class="card-body">
                  <div class="chart-container">
                    <canvas id="barChart"></canvas>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 mb-4">
              <!-- Project Card Example -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    Dormant Agencies - Agencies that have had no transactions in the last 2 months.
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row mb-4">
                    <div class="col-md-12">
                      <div class="card shadow h-100 py-2">
                        <div class="card-body">
                          <div class="table-responsive p-0">
                            <!-- <table class="table" id="agency_table"> -->
                            <table class="table table-bordered" id="srt_agency_table" width="100%" cellspacing="0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">AGENCY NAME</th>
                                  <th scope="col">ABBREVIATION</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(agency, index) of this
                                  .domartSrtAgencies" v-bind:key="agency.srt_agency_id">
                                  <td>{{ index + 1 }}</td>
                                  <td>
                                    {{ agency.agency_name.toUpperCase() }}
                                  </td>
                                  <td>
                                    {{ agency.abbreviation.toUpperCase() }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="card-footer text-white bg-gradient-primary">
                          <h6 style="font-size: 0.9em; font-weight: 900">
                            SRT AGENCIES
                          </h6>
                          <p style="font-size: 0.8em">
                            <i class="fa fa-check"></i>Manage all SRT agencies.
                            They are merged with the standardized agencies and
                            show only those with no transactions in the past 2
                            months
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- </div> -->
            <!-- <div class="row"> -->
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">Error: {{ error.message }}</div>
            <div class="col-xl-12 col-lg-12 mb-4" v-else-if="result">
              <!-- Project Card Example -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary">
                    {{ year }} SLA Report - Priority Transactions
                  </h6>
                </div>
                <div class="card-body">
                  <div class="row mb-4">
                    <div class="col-md-12">
                      <div class="card shadow h-100 py-2">
                        <div class="card-body">
                          <div class="table-responsive p-0">
                            <!-- <table class="table" id="agency_table"> -->
                            <table class="table table-bordered" id="transactions" width="100%" cellspacing="0">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">SCAN</th>
                                  <th scope="col">LEVEL</th>
                                  <th scope="col">TYPE</th>
                                  <th scope="col">PROCESSOR NAME</th>
                                  <th scope="col">ACTION</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                        <div class="card-footer text-white bg-gradient-primary">
                          <h6 style="font-size: 0.9em; font-weight: 900">
                            Priority Transactions for SLA
                          </h6>
                          <p style="font-size: 0.8em">
                            <i class="fa fa-check"></i>Manage all transactions
                            that are Priority for the SLA limit set of 2 months
                          </p>
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <pre v-else>{{ result }}</pre> -->
          </div>
          <!-- /.container-fluid -->
        </div>
      </div>
      <!-- End of Main Content -->
      <Footer />
      <!-- End of Content Wrapper -->
    </div>
  </div>
  <!-- End of Page Wrapper -->
</template>
<style>
.scrollable-tooltip {
  max-height: 150px;
  /* Set a fixed height */
  overflow-y: auto;
  /* Make it scrollable */
  padding-right: 10px;
  /* Add padding for better UX */
}
</style>
<script>
// @ is an alias to /src
import Topbar from "@/components/common/Topbar.vue";
import Footer from "@/components/common/Footer.vue";
import SideBar from "@/components/common/Navbar.vue";
import "datatables.net-dt/js/dataTables.dataTables";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.css";
import "@/assets/vendor/datatables/jquery.dataTables.min.js";
import "@/assets/vendor/datatables/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { useQuery } from "@vue/apollo-composable";
import pdfMake from "pdfmake/build/pdfmake";
import { computed } from "vue";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {
  INSIGHTS,
  ONLINETRANSACTIONSINSIGHTS_QUERY,
  SCANNEDTRANSINSIGHTS_QUERY,
  DOMANT_AGENCIES,
  INSIGHTS_QUERY,
  DASHBOARD_INSIGHTS2,
  DELAYED_SCANS,
} from "@/graphql";

const currentDate = new Date();

// Set the start date to the first day of the current month
const startDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);

const endDate = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0
);

const formattedStartDate = startDate.toISOString(); // Adjust the format as needed
const formattedEndDate = endDate.toISOString(); // Adjust the format as needed
export default {
  name: "Dashboard",
  components: {
    Topbar,
    Footer,
    SideBar,
  },
  data() {
    const { result, loading, error, refetch, fetchMore } = useQuery(
      DASHBOARD_INSIGHTS2,
      {
        offset: 0,
        limit: 10,
        search: null,
      }
    );
    const priorities = computed(() => result.value?.ScanPriorityServerSide);
    const ScanPriorityServerSide = computed(
      () => result.value?.ScanPriorityServerSide?.data
    );
    return {
      message: "",
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      user_id: "",
      role: "",
      srtsPrioritycount: 0,
      turnOverAssignedScans: [],
      dailyScansTotal: [],
      myLineChart: null,
      // myDoughnutChart: null,
      progressbar: null,
      myBarChart: null,
      domartSrtAgencies: [],
      onlineTransactionsInsights: [],
      scannedInvoiceTransactionsInsights: [],
      result,
      loading,
      error,
      year: currentDate.getFullYear(),
      ScanPriorityServerSide,
      priorities,
      fetchMore,
    };
  },

  apollo: {
    dailyScansTotal: {
      query: INSIGHTS,
      variables: {
        input: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      },
    },
    onlineTransactionsInsights: {
      query: ONLINETRANSACTIONSINSIGHTS_QUERY,
    },
    scannedInvoiceTransactionsInsights: {
      query: SCANNEDTRANSINSIGHTS_QUERY,
    },
    turnOverAssignedScans: {
      query: DELAYED_SCANS,
      variables() {
        return {
          limit: 10,
          offset: 0,
        };
      },
    },
    domartSrtAgencies: {
      query: DOMANT_AGENCIES,
    },
  },
  methods: {
    async loadChartData() {
      var barChart = document.getElementById("barChart").getContext("2d");

      // if (this.myDoughnutChart) this.myDoughnutChart.destroy();
      if (this.myBarChart) this.myBarChart.destroy();
      const responseBar = await this.$apollo.query({
        query: INSIGHTS_QUERY,
      });

      const insightsDataLine = responseBar.data.transactionCountPerAgency;
      const months = insightsDataLine.labels;
      const agencyData = insightsDataLine.data;
      let datasets = [];

      agencyData.forEach((agency) => {
        const label = agency.label;
        const data = agency.data;
        const borderColor = agency.borderColor;
        const pointBackgroundColor = agency.pointBackgroundColor;
        const fill = agency.fill;

        const dataset = {
          label: label,
          data: data,
          borderColor: borderColor,
          pointBackgroundColor: pointBackgroundColor,
          backgroundColor: borderColor,
          fill: true,
        };
        datasets.push(dataset);
      });
      // Get the screen width
      const screenWidth = window.innerWidth;

      // Create Line chart
      this.myBarChart = new Chart(barChart, {
        type: "bar",
        data: {
          labels: months,
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: null,
            // position: "right",
            // labels: {
            //   padding: 15,
            //   fontColor: "#08431A",
            // },
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: function (tooltipItems) {
                return tooltipItems[0].label;
              },
              label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const datasetLabel = dataset.label || "";
                const value = dataset.data[tooltipItem.index];
                return `${datasetLabel}: $${value}`;
              },
              footer: function () {
                return "";
              },
            },
            displayColors: false,
            custom: function (tooltip) {
              if (!tooltip) return;
              // Add custom styling class
              tooltip.el.classList.add('scrollable-tooltip');
            }
          },
          tooltips: {
            mode: "index", // Use 'index' mode to display all datasets' data for the hovered index
            intersect: false, // Ensure tooltip shows for all items on the same index
            callbacks: {
              title: function (tooltipItems) {
                return tooltipItems[0].label;
              },
              label: function (tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const datasetLabel = dataset.label || "";
                const value = dataset.data[tooltipItem.index];
                return `${datasetLabel}: $${value}`;
              },
              footer: function () {
                return "";
              },
            },
            displayColors: true,
          },
          // title: {
          //   display: true,
          //   // text: "Transactions Count and Amount Per agency per Month",
          //   position: "top",
          //   fontSize: 16,
          //   fontColor: "#08431A",
          //   padding: 20,
          // },
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Months Of The Year",
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Currency ($)",
                },
              },
            ],
          },

          layout: {
            padding: { left: 20, right: 20, top: 20, bottom: 20 },
          },
        },
      });
      this.domartSrtAgencies = [];
      await this.$apollo

        .query({
          query: DOMANT_AGENCIES,
        })
        .then((response) => {
          this.domartSrtAgencies = response.data.domartSrtAgencies;
        });

      setTimeout(function () {
        $("#srt_agency_table").DataTable({
          destroy: true,
          lengthChange: true,
          lengthMenu: [10, 50, 100, 150, 200],
          pageLength: 10,
          processing: true,
          paging: true,
          info: false,
          dom: "Blfrtip",

          buttons: [
            {
              extend: "csv",
              text: '<i class="fa fa-file-excel"></i>',
              className: "btn btn-sm btn-outline-success mb-3 text-success",
            },
            {
              extend: "pdf",
              text: '<i class="fa fa-file-pdf"></i>',
              className: "btn btn-sm btn-outline-danger mb-3 text-danger",
            },
            {
              extend: "print",
              text: '<i class="fa fa-print"></i>',
              className:
                "btn btn-sm btn-outline-secondary mb-3 text-secondary ",
            },
            {
              extend: "colvis",
              text: '<i class="fa fa-eye"></i>',
              className: "btn btn-sm btn-outline-secondary mb-3 text-primary",
            },
          ],
        });
      }, 300);

      const self = this;
      await self.fetchMore({
        variables: {
          offset: 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // Your existing code...
        },
      });
      var columns = [
        { data: "scan_id" },
        {
          data: "scan_doc_name",
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              return data ? data.toUpperCase().replace(/_/g, " ") : "";
            }
            return data;
          },
        },
        {
          data: "scan_status",
          render: function (data, type, row) {
            if (type === "display") {
              return data === 0
                ? "NEW SCAN"
                : data === 1
                  ? "ASSIGNED SCAN"
                  : data === 2
                    ? "VALIDATION"
                    : data === 3
                      ? "FORMAT ERRORS"
                      : "VALIDATION";
            }
            return data;
          },
        },
        {
          data: "scan_type",
          render: function (data, type, row) {
            if (type === "display") {
              return data === 0 ? "INVOICE" : "CITATION";
            }
            return data;
          },
        },
        {
          data: "processor",
          render: function (data, type, row) {
            if (type === "display" || type === "filter") {
              return data ? data.first_name.toUpperCase() : "-";
            }
            return data;
          },
        },
        {
          data: null,
          render: function (data, type, row) {
            if (type === "display") {
              return (
                '<button class="btn btn-sm btn-warning work-on-button" data-row=\'' +
                JSON.stringify(row) +
                "'  >WORK ON</button>"
              );
            }
            return data;
          },
        },
      ];
      var ex_col = [0, 1, 2, 3];

      var srtData = {
        recordsTotal: self?.result?.scanPriorityServerSide?.count,
        recordsFiltered: self?.result?.scanPriorityServerSide?.filteredCount,
        data: self?.result?.scanPriorityServerSide?.data,
      };

      function arraysAreEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
      }

      var dataTable = $("#transactions").DataTable({
        destroy: true,
        lengthChange: true,
        lengthMenu: [10, 50, 100, 150, 200],
        columns: columns,
        processing: true,
        pagingType: "full",
        paging: true,
        info: true,
        serverSide: true,
        ajax: function (data, callback, settings) {
          callback(srtData);
        },

        dom: "Blfrtip",
        buttons: [
          {
            extend: "csv",
            text: '<i class="fa fa-file-excel"></i>',
            className: "btn btn-sm btn-outline-success mb-3 text-success",
          },
          {
            extend: "pdf",
            text: '<i class="fa fa-file-pdf"></i>',
            className: "btn btn-sm btn-outline-danger mb-3 text-danger",
          },
          {
            extend: "print",
            text: '<i class="fa fa-print"></i>',
            className: "btn btn-sm btn-outline-secondary mb-3 text-secondary",
          },
          {
            extend: "colvis",
            text: '<i class="fa fa-eye"></i>',
            className: "btn btn-sm btn-outline-secondary mb-3 text-primary",
          },
        ],

        language: {
          oPaginate: {
            sNext: '<i class="fa fa-forward"></i>',
            sPrevious: '<i class="fa fa-backward"></i>',
            sFirst: '<i class="fa fa-step-backward"></i>',
            sLast: '<i class="fa fa-step-forward"></i>',
          },
        },
      });

      function fetchData(offset, length) {
        self.fetchMore({
          variables: {
            offset: offset,
            limit: length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousResult;
            let newData = fetchMoreResult.ScanPriorityServerSide.data;
            if (!arraysAreEqual(newData, srtData.data)) {
              srtData.data = newData;
              dataTable.clear().rows.add(newData).draw(false);
            }
          },
        });
      }
      dataTable.on("draw.dt", function () {
        var pageInfo = dataTable.page.info();
        var offset = pageInfo.page * pageInfo.length;
        var length = pageInfo.length;
        fetchData(offset, length);
      });

      $("#transactions").on(
        "buttons-action",
        function (e, buttonApi, dataTable, node, config) {
          // console.log("Button " + buttonApi.text() + " was activated");
        }
      );
      $("#transactions").on("click", ".work-on-button", (event) => {
        var rowData = $(event.currentTarget).data("row");
        this.redirectToScanDetail(rowData);
      });

      function getRandomColor() {
        return `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)}, 0.7)`;
      }
    },
    redirectToScanDetail(scanId) {
      let scan_id = scanId.scan_id;
      localStorage.setItem("scan_id", scan_id);
      if (scanId.srt_status === 1) {
        this.$router.push({ path: "/srt-raws" });
      } else if (scanId.srt_status === 2) {
        this.$router.push({ path: "/validation" });
      } else if (scanId.srt_status === 3) {
        this.$router.push({ path: "/validate" });
      } else if (scanId.srt_status === 4) {
        this.$router.push({ path: "/unique-id-validation" });
      } else {
        this.$router.push({ path: "/unbilled" });
      }
      // }else if  (scanId.srt_status === 2) {
      //   this.$router.push({ path: "/citation-detail" });
      // }

      // this.$router.push({
      //   path: "/unbilled",
      // });
    },
    redirectToScan(srt) {
      localStorage.setItem("status", 2);
      localStorage.setItem("scan_id", srt);
      this.$router.push({ path: "/past-turnover" });
    },
  },

  async created() { },

  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    await this.loadChartData();
  },
};
</script>

<style scoped>
.hazard-blink {
  animation: blink 3s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* Media query to hide the legend on screens less than 768px (tablet and mobile) */
@media (max-width: 768px) {
  .chartjs-legend {
    display: none !important;
  }
}

.chart-container {
  position: relative;
  width: 100%;
  height: auto;
  /* Adjusts height automatically */
}

#barChart {
  width: 100% !important;
  height: 100% !important;
  /* Keeps aspect ratio */
}
</style>
